import { isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';

import { LoadableData } from '../../../../common/utils/LoadableData';
import { formatDate } from '../../../../common/utils/formatters';
import { createDataId } from '../../../../common/utils/dataId';
import { ToggleContent, ToggleContentType } from '../../../../components/ToggleContent/ToggleContent';
import { PurchaseOrderExtraStatus, PurchaseOrderHistoryAction, PurchaseOrderHistoryDTO } from '../../../../services/types/ApiTypes';
import { historyActionTranslations } from './PurchaseOrderHistoryHelper';
import { Typography } from '../../../../components/Ui/Typography';

import './PurchaseOrderHistory.scss';

export interface Props {
    purchaseOrderId: number;
    purchaseOrderHistoryLoadable: LoadableData<PurchaseOrderHistoryDTO[], string>;
}

export interface DispatchProps {
    getPurchaseOrderHistory: Dispatchable1<number>;
}

export type PurchaseOrderHistoryProps = Props & DispatchProps;

const PurchaseOrderHistory: React.FC<PurchaseOrderHistoryProps> = ({ purchaseOrderId, getPurchaseOrderHistory, purchaseOrderHistoryLoadable }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (purchaseOrderId) {
            getPurchaseOrderHistory(purchaseOrderId);
        }
    }, [purchaseOrderId]);

    const getAdditionalInfo = (history: PurchaseOrderHistoryDTO): string => {
        if (history.ActionId !== PurchaseOrderHistoryAction.FulfilmentChange) {
            return `${t('component.history.clonedFrom').toLowerCase()} ${history.AdditionalInfo}`;
        } else {
            return t('view.PurchaseOrders.History.AdditionalInfo.Fulfilment', { status: t(`view.PurchaseOrders.ExtraStatus.${PurchaseOrderExtraStatus[history.AdditionalInfo]}`) });
        }
    };

    return (
        <ToggleContent
            className="po-history__container"
            type={ToggleContentType.CONTENT_BLOCK}
            toggleContent={<Typography variant="h2">{t('component.history.heading')}</Typography>}
            defaultOpen={true}
        >
            {!isEmpty(purchaseOrderId && purchaseOrderHistoryLoadable.payload) ? (
                <ul className="po-history">
                    {purchaseOrderHistoryLoadable.payload.map((history, i) => (
                        <li key={i} className="po-history__entry" data-id={createDataId('poHistoryRow', i)}>
                            <div className="po-history__title">
                                <strong className="po-history__action" data-id={createDataId('poHistoryEntry', i, 'action')}>
                                    {t(historyActionTranslations[history.ActionId])}
                                </strong>
                                <span className="po-history__date" data-id={createDataId('poHistoryEntry', i, 'date')}>
                                    {formatDate(history.LogDate, 'dd.MM.yyyy HH:mm:ss')}
                                </span>
                            </div>
                            {history.AdditionalInfo && (
                                <span className="po-history__date" data-id={createDataId('poHistoryEntry', i, 'additionalInfo')}>
                                    {getAdditionalInfo(history)}
                                </span>
                            )}
                            <span data-id={createDataId('poHistoryEntry', i, 'nameAndInfo')}>{history.GroupMemberName}</span>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="no-history-entries">{t('component.purchaseOrderHistory.noHistory')}</p>
            )}
        </ToggleContent>
    );
};

export default PurchaseOrderHistory;
