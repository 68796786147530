import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { ATTACHMENTS_MAX_COUNT, ATTACHMENTS_MAX_SIZE, ATTACHMENTS_MAX_SIZE_TOTAL } from '../../../../common/utils/attachmentsHelper';
import { notify } from '../../../../common/utils/notify';
import { AttachmentFilesCountResponseDTO } from '../../../../services/types/ApiTypes';

import { createDataId, WithDataId } from '../../../../common/utils/dataId';
import { readFileContentAsBase64 } from '../../../../common/utils/fileReader';
import { formatDate } from '../../../../common/utils/formatters';
import { BaseComponent } from '../../../../components/BaseComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import Icon, { IconSize, ICONS } from '../../../../components/Icon/Icon';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { Typography } from '../../../../components/Ui/Typography';
import { PurchaseOrdersAddViewProps } from '../../PurchaseOrdersAddView';

import './RelatedDocuments.scss';

export interface Props
    extends Pick<
        PurchaseOrdersAddViewProps,
        'uploadFile' | 'deleteFile' | 'downloadFile' | 't' | 'poFilesLoadable' | 'uploadFileLoadable' | 'uploadFileProgress' | 'openFileInNewWindow' | 'downloadFileLoadable'
    > {
    purchaseOrderId: number;
    isReadOnly: boolean;
    isEditting: boolean;
    attachmentsCount: AttachmentFilesCountResponseDTO;
}

class RelatedDocuments extends BaseComponent<Props & WithDataId> {
    private fileInputRef = React.createRef<HTMLInputElement>();

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !this.props.purchaseOrderId) {
            return;
        }
        const file = e.target.files[0];

        if (this.props.attachmentsCount) {
            const totalSizeToBe = this.props.attachmentsCount.TotalSize + file.size;
            if (file.size > ATTACHMENTS_MAX_SIZE || totalSizeToBe > ATTACHMENTS_MAX_SIZE_TOTAL) {
                {
                    notify.warning(this.props.t('component.attachments.fileTooBig'));
                }
            } else {
                readFileContentAsBase64(file).then(async (fileReaderResult) => {
                    this.props.uploadFile(fileReaderResult);
                });
            }
        }

        if (this.fileInputRef && this.fileInputRef.current) {
            this.fileInputRef.current.value = '';
        }
    };

    getIcon(extension: string) {
        extension = extension ? extension.toLowerCase() : '';
        if (['.xls', '.xlr', '.xlsx', '.xlsb', '.csv', '.ods', '.fods', '.odf'].includes(extension)) {
            return ICONS.EXCEL;
        }
        if (['.pdf'].includes(extension)) {
            return ICONS.PDF;
        }
        if (['.xml'].includes(extension)) {
            return ICONS.XML;
        }
        if (['.jpg', '.png', '.jpeg', '.tiff', '.bmp', '.gif', '.svg'].includes(extension)) {
            return ICONS.IMAGE;
        }
        if (['.doc', '.docs', '.docx', '.odt', '.fodt', '.rtf', '.pages'].includes(extension)) {
            return ICONS.DOCUMENT;
        }
        if (['.txt', '.tex', '.log', '.dat'].includes(extension)) {
            return ICONS.TXT;
        }
        if (['.zip', '.rar', '.7z'].includes(extension)) {
            return ICONS.ARCHIVE;
        }
        if (['.msg', '.eml'].includes(extension)) {
            return ICONS.EMAIL;
        }
        if (['.pps', '.ppt', '.pptx'].includes(extension)) {
            return ICONS.PRESENTATION;
        }
        return ICONS.FILE_BLANK;
    }

    getExtensionClass(extension: string) {
        return extension ? extension.toLowerCase().replace('.', '') : '';
    }

    render() {
        const { poFilesLoadable, t, isReadOnly, isEditting, uploadFileLoadable, uploadFileProgress, downloadFileLoadable, attachmentsCount } = this.props;
        const canAddFiles = attachmentsCount && attachmentsCount.AttachmentsCount < ATTACHMENTS_MAX_COUNT && attachmentsCount.TotalSize < ATTACHMENTS_MAX_SIZE_TOTAL;

        return (
            <ContentBlock className="related-docs" loading={poFilesLoadable.loading || uploadFileLoadable.loading || downloadFileLoadable.loading} isMuted={isEditting} dataId={this.props.dataId}>
                <Tooltip
                    trigger="manual"
                    isVisible={uploadFileLoadable.loading && uploadFileProgress < 100}
                    hideOnClick={false}
                    content={<span className="content-block__loading-text">{t('view.PurchaseOrders.RelatedDocuments.UploadProgress', { progress: uploadFileProgress })}</span>}
                    delay={0}
                    placement="top-end"
                >
                    <ContentBlockHeader>
                        <Typography variant="h2">{t('view.PurchaseOrders.RelatedDocuments.Title')}</Typography>

                        {!isReadOnly && (
                            <Tooltip content={canAddFiles ? t('view.PurchaseOrders.RelatedDocuments.AddNew') : t('view.PurchaseOrders.RelatedDocuments.MaxSizeOrAmountReached')}>
                                <label htmlFor="input" className="related-docs__add">
                                    <Icon name={ICONS.PLUS} />
                                    <input
                                        ref={this.fileInputRef}
                                        onChange={this.handleFileChange}
                                        data-id="import.document"
                                        type="file"
                                        id="input"
                                        style={{ position: 'absolute', zIndex: -1, width: '100%', opacity: 0, left: '0' }}
                                        disabled={!canAddFiles}
                                    />
                                </label>
                            </Tooltip>
                        )}
                    </ContentBlockHeader>
                </Tooltip>
                {poFilesLoadable.payload && !isEmpty(poFilesLoadable.payload) && (
                    <ContentBlockFooter type={ContentBlockFooterType.NARROW}>
                        <ul className="related-docs__list" data-id={createDataId('relatedDocumentsList')}>
                            {poFilesLoadable.payload.map((file, id) => (
                                <li className="related-docs__document" key={id} data-id={'documentItem'}>
                                    <Icon name={this.getIcon(file.Extension)} size={IconSize.LG} className={`related-docs__icon ${this.getExtensionClass(file.Extension)}`} />
                                    <div>
                                        <span className="related-docs__document-name">{file.FileName}</span>
                                        <ul className="related-docs__meta">
                                            {file.UserFullName && <li className="related-docs__meta-field">{file.UserFullName}</li>}
                                            <span className="related-docs__meta-separator">|</span>
                                            <li className="related-docs__meta-field">{formatDate(file.UploadedDateTime)}</li>
                                        </ul>
                                    </div>
                                    <div className="related-docs__actions">
                                        <Tooltip content={t('view.PurchaseOrders.RelatedDocuments.Open')}>
                                            <Button
                                                icon={ICONS.OPEN_EXTERNAL}
                                                iconSize={IconSize.SM}
                                                buttonType={ButtonType.ICON}
                                                dataId={createDataId('openDocumentInNewWindow')}
                                                onClick={() => {
                                                    this.props.openFileInNewWindow(file.Id);
                                                }}
                                            />
                                        </Tooltip>
                                        <DropdownMenu
                                            dataId={createDataId('purchaseOrders', 'details', 'relatedDocuments', 'documentActions', 'dropdown')}
                                            items={
                                                <>
                                                    <DropdownMenuItem
                                                        onClick={() => {
                                                            this.props.downloadFile(file);
                                                        }}
                                                        icon={this.getIcon(file.Extension)}
                                                        dataId="downloadFile"
                                                    >
                                                        {t('view.PurchaseOrders.RelatedDocuments.Download')}
                                                    </DropdownMenuItem>
                                                    {!isReadOnly && (
                                                        <DropdownMenuItem
                                                            onClick={() => {
                                                                this.props.deleteFile(file);
                                                            }}
                                                            icon={ICONS.DELETE}
                                                            dataId="deleteFile"
                                                        >
                                                            {t('views.global.Delete')}
                                                        </DropdownMenuItem>
                                                    )}
                                                </>
                                            }
                                        >
                                            <Button icon={ICONS.MORE} iconSize={IconSize.SM} buttonType={ButtonType.ICON} dataId={createDataId('documentMoreActions')} />
                                        </DropdownMenu>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </ContentBlockFooter>
                )}
            </ContentBlock>
        );
    }
}

export default RelatedDocuments;
