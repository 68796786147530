import { groupBy, map, maxBy } from 'lodash-es';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { Role } from '../../../../common/user/userPermissionUtil';
import AccessLevel from '../../../../components/AccessLevel/AccessLevel';
import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { ICONS } from '../../../../components/Icon/Icon';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { TypeaheadItem } from '../../../../components/Typeahead/TypeaheadAsync';
import { PurchaseOrderStatus, PurchaseOrderTaskAction, PurchaseOrderTaskDTO, GroupMemberApproverDTO } from '../../../../services/types/ApiTypes';
import { User } from '../../../../services/ApiClient';
import { DispatchProps } from '../../PurchaseOrdersAddView';
import { PurchaseOrdersAddViewState } from '../../PurchaseOrdersAddViewReducer';
import { createDataId, WithDataId } from '../../../../common/utils/dataId';
import withSuspense from '../../../../common/hocs/withSuspense';
import { Typography } from '../../../../components/Ui/Typography';

import AssignWorkflow from './AssignWorkflow';
import TaskGroup from './TaskGroup';

import './Workflow.scss';

export interface Props extends Pick<PurchaseOrdersAddViewState, 'retrievePOLoadable' | 'poTaskItemsLoadable'>, Pick<DispatchProps, 'doTaskAction'> {
    currentUser: User;
    onConfirm: (comment: string, isLastConfirmer: boolean) => void;
    potentialApprovers: (searchString: string) => Promise<Array<TypeaheadItem<GroupMemberApproverDTO>>>;
    onAddApprover: (approver: GroupMemberApproverDTO, ornerNo: number) => void;
    isApproverAddingAvailable: boolean;
    isEditting: boolean;
    canAssignByParentComponent?: () => Promise<string>;
}

export type WorkflowProps = WithTranslation & Props & WithDataId;

export interface WorkflowState {
    assignOpen: boolean;
}

class Workflow extends BaseStatefulComponent<WorkflowProps, WorkflowState> {
    constructor(props: WorkflowProps) {
        super(props);
        this.state = {
            assignOpen: false,
        };
    }

    toggleAssignOpen = () => {
        this.setState({
            assignOpen: !this.state.assignOpen,
        });
    };

    groupTaskItems(taskItems: PurchaseOrderTaskDTO[]) {
        return groupBy(taskItems, 'OrderNo');
    }

    isAssigned() {
        const { retrievePOLoadable } = this.props;
        return retrievePOLoadable.payload && retrievePOLoadable.payload.OrderStatus !== PurchaseOrderStatus.New;
    }

    showAssignButton() {
        const { retrievePOLoadable } = this.props;
        return retrievePOLoadable.payload && [PurchaseOrderStatus.New, PurchaseOrderStatus.Rejected].includes(retrievePOLoadable.payload.OrderStatus);
    }

    handleOnReject = (comment: string) => {
        const { doTaskAction } = this.props;
        doTaskAction(undefined, comment, PurchaseOrderTaskAction.Rejected);
    };

    letConfirmTask = (orderNo: number): string => {
        const { poTaskItemsLoadable } = this.props;
        const isLastConfirm = maxBy(poTaskItemsLoadable.payload, (taskItem) => taskItem?.OrderNo)?.OrderNo === orderNo;
        if (!this.props.retrievePOLoadable?.payload?.Supplier && isLastConfirm) {
            return this.props.t('view.PurchaseOrders.Workflow.supplierMissingMessage');
        }
        return '';
    };

    handleOnConfirm = (comment: string, orderNo: number) => {
        const { poTaskItemsLoadable } = this.props;
        const isLastConfirm = maxBy(poTaskItemsLoadable.payload, (taskItem) => taskItem.OrderNo).OrderNo === orderNo;
        this.props.onConfirm(comment, isLastConfirm);
    };

    render() {
        const { t, retrievePOLoadable, poTaskItemsLoadable, dataId, isEditting } = this.props;
        const groupedTaskItems = this.groupTaskItems(poTaskItemsLoadable.payload);
        return (
            <>
                {retrievePOLoadable.payload && this.state.assignOpen ? (
                    <AssignWorkflow
                        isEditting={isEditting}
                        onClose={this.toggleAssignOpen}
                        doTaskAction={this.props.doTaskAction}
                        canAssignByParentComponent={this.props.canAssignByParentComponent}
                        dataId={createDataId(dataId, 'assignWorkflow')}
                    />
                ) : (
                    <ContentBlock className="workflow" isMuted={isEditting} loading={retrievePOLoadable.loading || poTaskItemsLoadable.loading} dataId={createDataId(dataId)}>
                        <ContentBlockHeader>
                            <Typography variant="h2">
                                {t('view.PurchaseOrders.Confirmations')}
                                {/* <Icon name={ICONS.INFO} size={IconSize.XS} className="workflow__info" /> not in MVP */}
                            </Typography>
                            {this.showAssignButton() && (
                                <AccessLevel role={Role.CanAddPurchaseOrders}>
                                    <Tooltip content={t('view.PurchaseOrders.Workflow.AssignDescription')}>
                                        <Button dataId={createDataId(dataId, 'toggleAssign')} buttonType={ButtonType.ICON} icon={ICONS.ARROW_RIGHT} onClick={this.toggleAssignOpen} />
                                    </Tooltip>
                                </AccessLevel>
                            )}
                        </ContentBlockHeader>
                        {this.isAssigned() && (
                            <ContentBlockFooter type={ContentBlockFooterType.NARROW} className={'workflow__tasks'}>
                                {poTaskItemsLoadable.payload &&
                                    map(groupedTaskItems, (taskItems, orderNo) => (
                                        <TaskGroup
                                            loading={poTaskItemsLoadable.loading}
                                            className={'workflow__task-group'}
                                            taskItems={taskItems}
                                            key={orderNo}
                                            currentUser={this.props.currentUser}
                                            onConfirm={(comment: string) => {
                                                this.handleOnConfirm(comment, Number(orderNo));
                                            }}
                                            confirmErrorText={this.letConfirmTask(Number(orderNo))}
                                            onReject={this.handleOnReject}
                                            potentialApprovers={this.props.potentialApprovers}
                                            orderNo={Number(orderNo)}
                                            onAddApprover={this.props.onAddApprover}
                                            isApproverAddingAvailable={this.props.isApproverAddingAvailable}
                                            dataId={createDataId(dataId || 'workflow', 'taskGroup', String(orderNo))}
                                        />
                                    ))}
                            </ContentBlockFooter>
                        )}
                    </ContentBlock>
                )}
                {ReactDOM.createPortal(
                    <CSSTransition unmountOnExit={true} classNames="fade" in={this.state.assignOpen} timeout={250}>
                        <div className="workflow__overlay" onClick={this.toggleAssignOpen} />
                    </CSSTransition>,
                    document.body,
                )}
            </>
        );
    }
}

export default withSuspense(withTranslation()(Workflow));
