import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import { OutsideEventListener } from '../../common/utils/OutsideEventListener';
import { createDataId } from '../../common/utils/dataId';
import { Button } from '../Ui/Button';
import Tooltip from '../../components/Tooltip/Tooltip';
import Icon from '../../components/Icon/Icon';
import { ConfirmationTooltipProps } from './ConfirmationTooltipTypes';
import { buttonColorMap } from './ConfirmationTooltipHelper';

import './ConfirmationTooltip.scss';

const mainClassName = 'confirmation-tooltip';

const ConfirmationTooltip: React.FC<React.PropsWithChildren<ConfirmationTooltipProps>> = ({
    children,
    dataId,
    type = 'default',
    title,
    cancelText,
    confirmText,
    confirmButtonIcon,
    onConfirm,
    open,
    onCancel,
    placement = 'bottom',
    className,
    buttonsSize,
}) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [outsideEventListener, setOutsideEventListener] = useState<OutsideEventListener>();
    const popupRef = useRef<HTMLElement>();
    const classes = classNames(mainClassName, `${mainClassName}--${type.toString().toLowerCase()}`, className);
    const isDialogOpen = open || isOpen;
    const registerOutsideListener = () => {
        if (outsideEventListener) {
            outsideEventListener.stop();
        }
        const outsideListener = new OutsideEventListener(popupRef, () => setIsOpen(false), false);
        outsideListener.start();
        setOutsideEventListener(outsideListener);
    };

    const unregisterOutsideListener = () => {
        if (outsideEventListener) {
            outsideEventListener.stop();
            setOutsideEventListener(null);
        }
    };

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleCancel = () => {
        setIsOpen(false);
        onCancel?.();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    const assignRef = (el: HTMLElement) => {
        popupRef.current = el;
    };

    useEffect(() => {
        if (isDialogOpen) {
            registerOutsideListener();
        } else {
            unregisterOutsideListener();
        }
    }, [isDialogOpen]);

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <Tooltip content={t('component.invoiceRows.table.buttons.discard.tooltip')}>
                        <div data-id={createDataId(dataId, 'button')} onClick={handleClick} ref={ref} className={`${mainClassName}__button-delete`}>
                            {children}
                        </div>
                    </Tooltip>
                )}
            </Reference>

            {ReactDOM.createPortal(
                <CSSTransition unmountOnExit={true} classNames="fade" in={isDialogOpen} timeout={150}>
                    <Popper
                        innerRef={assignRef}
                        positionFixed
                        placement={placement}
                        modifiers={{
                            offset: {
                                offset: '0, 12px',
                            },
                        }}
                    >
                        {({ ref, style, placement, arrowProps }) => (
                            <div data-id={dataId} className={classes} ref={ref} style={style} data-placement={placement} onClick={(e) => e.stopPropagation()}>
                                <span className={`${mainClassName}__arrow`} ref={arrowProps.ref} style={arrowProps.style} />
                                <div className={`${mainClassName}__content`}>
                                    {title && (
                                        <div data-id={createDataId(dataId, 'title')} className={`${mainClassName}__content-text`}>
                                            {title}
                                        </div>
                                    )}
                                    <div className={`${mainClassName}__bottom-content`}>
                                        <Button dataId={createDataId(dataId, 'button', 'cancel')} onClick={handleCancel} variant="outlined" size={buttonsSize}>
                                            {cancelText || t('views.global.Cancel')}
                                        </Button>
                                        <Button
                                            dataId={createDataId(dataId, 'button', 'confirm')}
                                            color={buttonColorMap[type]}
                                            onClick={handleConfirm}
                                            startIcon={confirmButtonIcon ? <Icon iconName={confirmButtonIcon} /> : null}
                                            size={buttonsSize}
                                        >
                                            {confirmText || t('views.global.Delete')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popper>
                </CSSTransition>,
                document.body,
            )}
        </Manager>
    );
};

export default ConfirmationTooltip;
