import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import { Typography } from '../../../../../../components/Ui/Typography';
import Icon, { ICONS } from '../../../../../../components/Icon/Icon';
import { Button } from '../../../../../../components/Ui/Button';
import { NotificationStatus } from '../../../../../../components/Notifications/Notification';
import { getUniqueId } from '../../../../../../views/purchase-orders-new/PurchaseOrdersNewHelper';
import { notify } from '../../../../../../common/utils/notify';
import { ATTACHMENTS_MAX_COUNT, ATTACHMENTS_MAX_SIZE_TOTAL } from '../../AttachmentsHelper';
import { isSupportedExtension, convertBytesToMb, translations } from './FileInputHelper';
import { FileInputProps, FileType } from './FileInputTypes';

import './FileInput.scss';

const FileInput: React.FC<FileInputProps> = ({ files, dropZoneId, totalFileSize, title, dataId, onChange }) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
    });

    const { t } = useTranslation();

    const fileInputRef = useRef<HTMLInputElement>();

    const dropZone = document?.getElementById(dropZoneId) || document.body;

    const documentName = title?.toLowerCase();

    const handleChange = (file: File) => {
        const id = getUniqueId();
        const reader = new FileReader();
        const toastId = file.name;
        reader.onloadstart = function() {
            const title = t(translations.notificationUploadingTitle, { name: toastId });
            const message = t(translations.notificationUploadingText, { percentage: 0 });
            notify.info(message, title, {
                toastOptions: { toastId },
            });
        };
        reader.onprogress = function(event) {
            if (event.loaded && event.total) {
                setTimeout(() => {
                    const percentage = ((event.loaded / event.total) * 100).toFixed(0);

                    const title = t(translations.notificationUploadingTitle, { name: file.name });
                    const message = t(translations.notificationUploadingText, { percentage });

                    notify.update(message, title, { toastOptions: { toastId } }, NotificationStatus.INFO);
                }, 0);
            }
        };
        reader.onload = function() {
            const successTitle = t(translations.notificationSuccessTitle, { name: documentName });
            const successMessage = t(translations.notificationSuccessText);
            if (reader.result instanceof ArrayBuffer) {
                const bytes = new Uint8Array(reader.result);
                const fileObject: FileType = {
                    key: id,
                    fileName: file.name,
                    fileSize: file.size,
                    bytes,
                };
                onChange(fileObject);
                setTimeout(() => {
                    notify.update(successMessage, successTitle, { toastOptions: { toastId } }, NotificationStatus.SUCCESS);
                }, 500);
            }
        };
        reader.onerror = function() {
            notify.error(t(translations.notificationErrorText), t(translations.notificationErrorTitle));
        };
        reader.onabort = function() {
            notify.error(t(translations.notificationErrorText), t(translations.notificationErrorTitle));
        };
        reader.readAsArrayBuffer(file);

        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const validateFileAndSave = (file: File) => {
        if (!isSupportedExtension(file.name)) {
            notify.error(t(translations.notificationErrorUnsupportedTypeText), t(translations.notificationErrorUnsupportedTypeTitle));
        } else if (totalFileSize + file.size > ATTACHMENTS_MAX_SIZE_TOTAL) {
            const errorTotalSizeTitle = t(translations.notificationErrorTotalSizeTitle, { number: convertBytesToMb(ATTACHMENTS_MAX_SIZE_TOTAL) });
            const errorTotalSizeMessage = t(translations.notificationErrorTotalSizeText, { name: documentName });
            notify.error(errorTotalSizeMessage, errorTotalSizeTitle);
        } else if (files.length === ATTACHMENTS_MAX_COUNT) {
            const errorMaxCountTitle = t(translations.notificationErrorTitle);
            const errorMaxCountMessage = t(translations.notificationErrorMaxAmountText, { number: ATTACHMENTS_MAX_COUNT });
            notify.error(errorMaxCountMessage, errorMaxCountTitle);
        } else {
            handleChange(file);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) {
            return;
        }
        const file = e.target.files[0];

        validateFileAndSave(file);
    };

    const handleUpload = () => {
        fileInputRef.current.click();
    };

    function onDrop(files: File[]) {
        files.forEach((file) => {
            validateFileAndSave(file);
        });
    }

    const onDragEnter = useCallback((e) => {
        setIsDragging(true);
        e.preventDefault();
        return false;
    }, []);

    const onDragLeave = useCallback((e) => {
        const { relatedTarget } = e;
        if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
            setIsDragging(false);
        }
        e.preventDefault();
        return false;
    }, []);

    const onDragDrop = useCallback((e) => {
        setIsDragging(false);
        e.preventDefault();
    }, []);

    useEffect(() => {
        dropZone?.addEventListener('dragenter', onDragEnter);
        dropZone?.addEventListener('dragleave', onDragLeave);
        dropZone?.addEventListener('drop', onDragDrop);

        return () => {
            dropZone?.removeEventListener('dragenter', onDragEnter);
            dropZone?.removeEventListener('dragleave', onDragLeave);
            dropZone?.removeEventListener('drop', onDragDrop);
        };
    }, [onDragLeave, onDragDrop, onDragEnter]);

    return (
        <div data-id={dataId} className="file-input__upload">
            <label htmlFor="input" className="file-input__upload-content">
                <Button startIcon={<Icon iconName={ICONS.UPLOAD_24} />} onClick={handleUpload} variant="outlined" size="sm">
                    {t(translations.buttonUpload)}
                </Button>
                <input ref={fileInputRef} onChange={handleFileChange} data-id="import.document" type="file" id="input" style={{ visibility: 'hidden', position: 'absolute' }} disabled={false} />
            </label>
            <Typography element="span" variant="body-lg">
                {t(translations.buttonDragFile)}
            </Typography>
            {ReactDOM.createPortal(
                <div className={classNames('file-input__dropzone', { 'file-input__dropzone--visible': isDragActive, 'file-input__dropzone--dragging': isDragging })} {...getRootProps()}>
                    <div className="file-input__dropzone-info">
                        <Icon name={ICONS.DRAG_AND_DROP} className="file-input__dropzone--icon" />
                        <Typography variant="h1" element="h1">
                            {t(translations.dropZoneTitle, { name: documentName })}
                        </Typography>
                        <Typography variant="label-lg" element="div">
                            {t(translations.dropZoneSubtitle, { name: documentName })}
                        </Typography>
                    </div>
                    <input {...getInputProps()} />
                </div>,
                dropZone,
            )}
        </div>
    );
};

export default FileInput;
