import React from 'react';
import { useTranslation } from 'react-i18next';

import { createDataId } from '../../../../../../common/utils/dataId';
import { Typography } from '../../../../../../components/Ui/Typography';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import Icon, { ICONS } from '../../../../../../components/Icon/Icon';
import { ButtonView } from '../../../../../../components/Ui/Button/ButtonView';
import { downloadBuffer, getIcon, mainClassName } from './AttachmentHelper';
import { AttachmentProps } from './AttachmentType';

import './Attachment.scss';

const Attachment: React.FC<AttachmentProps> = ({ file, dataId, onDelete }) => {
    const { t } = useTranslation();

    const fileSize = (file.fileSize / (1024 * 1024)).toFixed(1);

    const icon = getIcon(file.fileName);

    const handleDownload = async () => {
        downloadBuffer(file.bytes.buffer, file.fileName);
    };

    const handleDelete = () => {
        onDelete(file.key);
    };

    return (
        <div data-id={dataId} className={mainClassName}>
            <Icon className={`${mainClassName}__icon`} iconName={icon} />
            <div className={`${mainClassName}__data`}>
                <Typography dataId={createDataId(dataId, 'name')} className={`${mainClassName}__name`} element="div" variant="body-md">
                    {file.fileName}
                </Typography>
                <Typography dataId={createDataId(dataId, 'size')} className={`${mainClassName}__size`} element="div" variant="body-sm">
                    {`${fileSize} MB`}
                </Typography>
            </div>
            <div className={`${mainClassName}__buttons`}>
                <Tooltip content={t('component.attachments.button.download.tooltip')}>
                    <ButtonView dataId={createDataId(dataId, 'button', 'download')} onClick={handleDownload} iconButton variant="outlined">
                        <Icon iconName={ICONS.DOWNLOAD_24} />
                    </ButtonView>
                </Tooltip>
                <Tooltip content={t('component.attachments.button.delete.tooltip')}>
                    <ButtonView dataId={createDataId(dataId, 'button', 'delete')} onClick={handleDelete} iconButton variant="outlined">
                        <Icon iconName={ICONS.DELETE_24} />
                    </ButtonView>
                </Tooltip>
            </div>
        </div>
    );
};

export default Attachment;
