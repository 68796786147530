import { FastField, FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps } from 'formik';
import debounce from 'lodash-es/debounce';
import isEqual from 'lodash-es/isEqual';
import { noop } from 'lodash-es';
import * as React from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ArrowKeyStepper, AutoSizer, Column, ScrollIndices, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Dispatchable0, Dispatchable1, Dispatchable2, Dispatchable3 } from 'redux-dispatchers';
import RouteParser from 'route-parser';

import { paths } from '../../common/router/routePaths';
import { isAuthorized, Role } from '../../common/user/userPermissionUtil';
import { createDataId } from '../../common/utils/dataId';
import { FileReaderResult } from '../../common/utils/fileReader';
import { LoadableData } from '../../common/utils/LoadableData';
import { formatMoney, formatMoneyAndCurrency, formatThousandsWithDecimals } from '../../common/utils/formatters';
import { DECIMAL_DP2_REGEXP, DECIMAL_DP4_REGEXP, PERCENTAGE_REGEXP } from '../../common/utils/validators';
import FormikEffect from '../../common/utils/FormikEffect';
import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import { Button, ButtonType } from '../../components/Buttons/Button';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../components/ContentBlock/ContentBlockHeader';
import { EditSupplierModalState } from '../../components/EditSupplierModal/EditSupplierModalReducer';
import Icon, { ICONS } from '../../components/Icon/Icon';
import { MainPage, MainPageAside, MainPageContent, MainPageType } from '../../components/MainPage/MainPage';
import { OnFocusField } from '../../components/OnFocusField/OnFocusField';
import TableHeader from '../../components/Table/TableHeader';
import { TextInputField, TextInputType } from '../../components/TextInput/TextInput';
import Tooltip from '../../components/Tooltip/Tooltip';
import { TypeaheadItem } from '../../components/Typeahead/TypeaheadAsync';
import {
    AttachmentFilesCountResponseDTO,
    AttachmentType,
    GroupMemberApproverDTO,
    GroupMemberApproversParamDTO,
    GroupMemberDTO,
    PurchaseOrderExtraStatus,
    PurchaseOrderFileAttachmentDTO,
    PurchaseOrderStatus,
    PurchaseOrderTaskAction,
    ApproversListDestination,
    SupplierDTO,
    PurchaseOrdersRowsDTO,
    PurchaseOrdersDTO,
} from '../../services/types/ApiTypes';
import { User } from '../../services/ApiClient';
import { emptyRowObject, getNetSum, getTotalSum, rowValueCalculationFns } from './PurchaseOrdersAddViewHelper';
import { PurchaseOrdersAddViewState } from './PurchaseOrdersAddViewReducer';
import api from '../../services/ApiServices';

import Header from './components/Header/Header';
import RelatedDocuments from './components/RelatedDocuments/RelatedDocuments';
import Workflow from './components/Workflow/Workflow';
import fieldNames, { rowFieldNames, PurchaseOrdersAddViewFields } from './purchaseOrderAddViewFields';
import PurchaseOrderHistoryWrapper from './components/HistoryCard/PurchaseOrderHistoryWrapper';
import { Fulfilment } from './components/Fulfilment/Fulfilment';
import { Typography } from '../../components/Ui/Typography';

import './PurchaseOrdersAddView.scss';

export interface Props
    extends Pick<
            PurchaseOrdersAddViewState,
            'retrievePOLoadable' | 'poRowsLoadable' | 'poFilesLoadable' | 'poTaskItemsLoadable' | 'uploadFileLoadable' | 'uploadFileProgress' | 'downloadFileLoadable' | 'updateExtraStatusLoadable'
        >,
        Pick<EditSupplierModalState, 'addSupplierLoadable'> {
    currentUser: User;
    updateSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
    potentialApprovers: Array<TypeaheadItem<GroupMemberDTO>>;
    isApproverAddingAvailable: boolean;
}

export interface State {
    selectedRow: number;
    selectedColumn: number;
    tableVisibleRowCount: number;
    tableRowCount: number;
    isArrowNavigationDisabled: boolean;
    ignoreNextCellChange: boolean;
    previousFocusedCell: string;
    previousFocusedCellValue: any;
    inputChanged: boolean;
    currentForm: FormikProps<{ Rows: PurchaseOrdersRowsDTO[] }>;
    attachmentsCount: AttachmentFilesCountResponseDTO;
    shouldUpdateAttachmentsCount: boolean;
    loadPo: number;
    isEditting: boolean;
}

export interface DispatchProps {
    addOrUpdatePO: Dispatchable1<PurchaseOrdersAddViewFields>;
    updatePoRow: Dispatchable2<number, Partial<PurchaseOrdersRowsDTO>>;
    deletePoRow: Dispatchable1<Partial<PurchaseOrdersRowsDTO>>;
    retrievePO: Dispatchable1<string | undefined>;
    retrievePORows: Dispatchable1<string | undefined>;
    retrievePOFiles: Dispatchable1<string | undefined>;
    uploadFile: Dispatchable1<FileReaderResult>;
    deleteFile: Dispatchable1<PurchaseOrderFileAttachmentDTO>;
    deletePurchaseOrder: Dispatchable1<number>;
    downloadFile: Dispatchable1<PurchaseOrderFileAttachmentDTO>;
    openFileInNewWindow: Dispatchable1<number>;
    doTaskAction: Dispatchable3<number, string, PurchaseOrderTaskAction>;
    showAddOrEditSupplierModal: Dispatchable0;
    getCompanyGroupMembers: Dispatchable0;
    addApprover: Dispatchable2<GroupMemberApproverDTO, number>;
    replaceUrlToDetails: Dispatchable0;
    exportPurchaseOrderToPdf: Dispatchable2<string, string>;
    duplicatePurchaseOrder: Dispatchable1<string>;
    updateExtraStatus: Dispatchable2<PurchaseOrderExtraStatus, boolean>;
}

export interface RouteParams {
    id: string;
}

export type PurchaseOrdersAddViewProps = Props & DispatchProps & WithTranslation & RouteComponentProps<RouteParams>;

class PurchaseOrdersAddView extends BaseStatefulComponent<PurchaseOrdersAddViewProps, State> {
    private rowHeight = 40;
    private headerHeight = 48;
    private poAddRoute = new RouteParser(paths.app.addPurchaseOrder);

    constructor(props: PurchaseOrdersAddViewProps) {
        super(props);
        this.state = {
            selectedRow: 0,
            selectedColumn: 0,
            tableVisibleRowCount: 1,
            tableRowCount: 1,
            isArrowNavigationDisabled: false,
            ignoreNextCellChange: false,
            previousFocusedCell: undefined,
            previousFocusedCellValue: undefined,
            inputChanged: false,
            currentForm: null,
            attachmentsCount: { TotalSize: 0, AttachmentsCount: 0 },
            shouldUpdateAttachmentsCount: false,
            loadPo: null,
            isEditting: false,
        };
    }

    componentDidMount() {
        const url = new URL(window.location.href.replace('/#', ''));
        const companyGuid = url.searchParams.get('companyGuid');
        if (!companyGuid) {
            if (this.props.match) {
                const poId = this.props.match.params.id;
                if (!this.props.retrievePOLoadable?.payload || this.props.retrievePOLoadable?.payload?.Id !== Number(poId)) {
                    this.props.retrievePO(poId);
                }

                if (poId) {
                    this.props.retrievePORows(poId);
                    this.props.retrievePOFiles(poId);
                }
            }

            this.props.getCompanyGroupMembers();
            this.handleResize();
            window.addEventListener('resize', this.debounceHandleResize);
            window.addEventListener('mousedown', this.handleMouseDown);
            window.addEventListener('keydown', this.handleInputBlur);
            window.addEventListener('keydown', this.handleKeyDown);
            window.addEventListener('keyup', this.handleKeyUp);
            window.addEventListener('blur', this.handleInputBlur);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debounceHandleResize);
        window.removeEventListener('mousedown', this.handleMouseDown);
        window.removeEventListener('keydown', this.handleInputBlur);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('blur', this.handleInputBlur);
    }

    componentDidUpdate(prevProps: { retrievePOLoadable: { payload: PurchaseOrdersDTO }; poRowsLoadable: { payload: PurchaseOrdersRowsDTO[] } }) {
        if (this.props.poRowsLoadable?.payload !== prevProps.poRowsLoadable?.payload) {
            this.setState(
                () => {
                    return {
                        tableRowCount: this.props.poRowsLoadable?.payload?.length,
                        tableVisibleRowCount: this.props.poRowsLoadable?.payload?.length,
                    };
                },
                () => {
                    this.handleResize();
                    this.debounceHandleResize();
                },
            );
        }

        if (this.props.retrievePOLoadable?.payload !== prevProps.retrievePOLoadable?.payload && this.props.retrievePOLoadable?.payload?.Id && this.poAddRoute.match(this.props.location.pathname)) {
            // if we are on add page and we have received the generated PO ID, then replace the URL to detail view, this way we can refresh without creating new POs
            this.props.replaceUrlToDetails();
        }

        // attachments count update when loading is initialized/reinitialized
        if (this.props.poFilesLoadable.loading && !this.state.shouldUpdateAttachmentsCount) {
            this.setState(() => {
                return { shouldUpdateAttachmentsCount: true };
            });
        }

        if (!this.props.poFilesLoadable.loading && this.state.shouldUpdateAttachmentsCount) {
            const poId = this.props.match.params.id;
            if (poId) {
                this.getAttachmentsCount(Number(poId));
                this.setState(() => {
                    return { shouldUpdateAttachmentsCount: false };
                });
            }
        }
    }

    handleEditClick = (state: boolean) => {
        this.setState({ isEditting: state });
    };

    getAttachmentsCount = (poId: number) => {
        api.invoice.getAllAttachmentFilesSize(AttachmentType.PurchaseOrder, poId).then((response) => {
            let newCount: AttachmentFilesCountResponseDTO = { AttachmentsCount: 0, TotalSize: 0 };
            if (response.data) {
                newCount = response.data;
            }
            this.setState(() => {
                return { attachmentsCount: newCount };
            });
        });
    };

    getFieldValue = (formik: FormikProps<{ Rows: PurchaseOrdersRowsDTO[] }>, rowIndex: number, dataKey: string, valueFormatter: (value: string) => string) => {
        const rows = formik.values.Rows;
        if (rows && rows[rowIndex]) {
            const value = rows[rowIndex][dataKey];
            if (valueFormatter && value) {
                return valueFormatter(value);
            }
            return value;
        }
        return '';
    };

    isReadOnly() {
        const { retrievePOLoadable } = this.props;
        if (!isAuthorized(Role.CanEditPurchaseOrders)) {
            return true;
        }
        const readonlyStatuses = [PurchaseOrderStatus.Confirmed, PurchaseOrderStatus.Deleted];
        if (!isAuthorized(Role.CanViewAllPurchaseOrders)) {
            readonlyStatuses.push(PurchaseOrderStatus.Assigned);
        }
        return retrievePOLoadable.payload && readonlyStatuses.includes(retrievePOLoadable.payload.OrderStatus);
    }

    handleOnConfirm = (comment: string, isLastConfirmer: boolean) => {
        const { doTaskAction } = this.props;
        if (isLastConfirmer && !this.props.retrievePOLoadable.payload?.Supplier) {
            return;
        }
        doTaskAction(undefined, comment, PurchaseOrderTaskAction.Confirmed);
    };

    loadItems = async (name = ''): Promise<{ text: string; value: GroupMemberApproverDTO }[]> => {
        const options: GroupMemberApproversParamDTO = {
            SearchString: name,
            ApproversListDestination: ApproversListDestination.PurchaseOrder,
        };
        const simpleNames = await api.company.getApproversList(options);
        return Promise.resolve(
            simpleNames.data.map((simpleName) => ({
                text: simpleName.Name,
                value: simpleName,
            })),
        );
    };

    render(): JSX.Element {
        const { t, retrievePOLoadable, poRowsLoadable, poFilesLoadable } = this.props;
        if (this.poAddRoute.match(this.props.location.pathname)) {
            // let's not render anything for /purchase-orders/add url, since we will redirect to details view anyway
            return null;
        }

        return (
            <MainPage className={'purchase-order'} type={MainPageType.HAS_SIDEBAR}>
                <MainPageContent>
                    <Header
                        orderStatus={retrievePOLoadable.payload && retrievePOLoadable.payload.OrderStatus}
                        t={t}
                        loading={retrievePOLoadable.loading}
                        isReadOnly={this.isReadOnly()}
                        isEditting={this.state.isEditting}
                        handleEditClick={this.handleEditClick}
                        showAddOrEditSupplierModal={this.props.showAddOrEditSupplierModal}
                        updateSupplierLoadable={this.props.updateSupplierLoadable}
                        deletePurchaseOrder={this.props.deletePurchaseOrder}
                        addSupplierLoadable={this.props.addSupplierLoadable}
                        updatePurchaseOrder={this.props.addOrUpdatePO}
                        dataId="poHeader"
                        exportPurchaseOrderToPdf={this.props.exportPurchaseOrderToPdf}
                        duplicatePurchaseOrder={this.props.duplicatePurchaseOrder}
                        purchaseOrderId={this.props.match.params.id}
                        updateExtraStatusLoadable={this.props.updateExtraStatusLoadable}
                        retrievePOLoadable={retrievePOLoadable}
                        history={this.props.history}
                    />
                    <Formik
                        key={poRowsLoadable.payload ? poRowsLoadable.payload[0]?.PurchaseOrderId : 'new'} // use key to reinitialize formik once data is loaded
                        initialValues={{ Rows: poRowsLoadable.payload }}
                        enableReinitialize={true}
                        onSubmit={noop}
                    >
                        {(formik: FormikProps<{ Rows: PurchaseOrdersRowsDTO[] }>) => (
                            <Form>
                                <ContentBlock loading={retrievePOLoadable.loading} isMuted={this.state.isEditting}>
                                    <FormikEffect formik={formik} onChange={this.handleFormSideEffects} />
                                    <FieldArray
                                        name={fieldNames.Rows}
                                        render={(arrayProps: FieldArrayRenderProps) => (
                                            <>
                                                <ContentBlockHeader>
                                                    <Typography variant="h1" dataId={createDataId('purchaseOrderRowsTitle')}>
                                                        {t('view.PurchaseOrders.Rows.Title')} ({formik.values?.Rows?.length})
                                                    </Typography>
                                                    {!this.isReadOnly() && (
                                                        <Tooltip content={t('view.PurchaseOrders.Rows.AddNew')}>
                                                            <Button
                                                                onClick={() => {
                                                                    this.debounceUpdatePoRow(this.props.retrievePOLoadable?.payload?.Id, emptyRowObject);
                                                                    this.setActiveCell(formik.values?.Rows?.length, 1);
                                                                }}
                                                                buttonType={ButtonType.ICON}
                                                                className="purchase-order__add"
                                                                icon={ICONS.PLUS}
                                                                type="button"
                                                                dataId={createDataId('purchaseOrderAddRow')}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </ContentBlockHeader>
                                                <ContentBlockBody dataId="contentBlock.purchaseOrderRows">
                                                    <ArrowKeyStepper
                                                        disabled={this.state.isArrowNavigationDisabled}
                                                        columnCount={9}
                                                        isControlled={true}
                                                        onScrollToChange={this.selectCell}
                                                        mode={'cells'}
                                                        rowCount={formik.values?.Rows?.length || 0}
                                                        scrollToColumn={this.state.selectedColumn}
                                                        scrollToRow={this.state.selectedRow}
                                                    >
                                                        {({ onSectionRendered, scrollToRow }) => (
                                                            <AutoSizer disableHeight={true} className="content-block__table">
                                                                {({ width }) => (
                                                                    <Table
                                                                        containerProps={{
                                                                            ['data-id']: createDataId('purchaseOrderRowsTable'),
                                                                        }}
                                                                        width={width}
                                                                        height={this.state.tableVisibleRowCount * this.rowHeight + this.headerHeight}
                                                                        headerHeight={this.headerHeight}
                                                                        scrollToIndex={scrollToRow}
                                                                        rowHeight={this.rowHeight}
                                                                        overscanRowCount={10}
                                                                        scrollToColumn={this.state.selectedColumn}
                                                                        rowCount={formik.values?.Rows?.length || 0}
                                                                        rowGetter={({ index }) => formik.values.Rows[index]}
                                                                        onSectionRendered={onSectionRendered}
                                                                        scrollToRow={scrollToRow}
                                                                        className="purchase-order__table"
                                                                    >
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            label="#"
                                                                            dataKey="id"
                                                                            width={20}
                                                                            flexShrink={0}
                                                                            flexGrow={0}
                                                                            cellRenderer={({ rowIndex }) => {
                                                                                return `${rowIndex + 1}.`;
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={250}
                                                                            flexGrow={1}
                                                                            label={t('view.PurchaseOrders.Rows.Column.Description')}
                                                                            dataKey={rowFieldNames.Description}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(
                                                                                    formik,
                                                                                    arrayProps,
                                                                                    rowIndex,
                                                                                    columnIndex,
                                                                                    dataKey,
                                                                                    t('view.PurchaseOrders.Rows.Column.Description.Placeholder'),
                                                                                    (value) => value,
                                                                                    256,
                                                                                );
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={30}
                                                                            label={t('view.PurchaseOrders.Rows.Column.Quantity')}
                                                                            flexGrow={1}
                                                                            dataKey={rowFieldNames.Quantity}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(formik, arrayProps, rowIndex, columnIndex, dataKey, '-', formatThousandsWithDecimals);
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={20}
                                                                            flexGrow={1}
                                                                            label={t('view.PurchaseOrders.Rows.Column.Unit')}
                                                                            dataKey={rowFieldNames.Unit}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(formik, arrayProps, rowIndex, columnIndex, dataKey, '-');
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={70}
                                                                            flexGrow={1}
                                                                            label={t('view.PurchaseOrders.Rows.Column.Price')}
                                                                            dataKey={rowFieldNames.UnitPrice}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(formik, arrayProps, rowIndex, columnIndex, dataKey, '-', formatThousandsWithDecimals);
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={70}
                                                                            flexGrow={1}
                                                                            label={t('view.PurchaseOrders.Rows.Column.NetValue')}
                                                                            dataKey={rowFieldNames.SumWithoutVat}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(formik, arrayProps, rowIndex, columnIndex, dataKey, '0.00', formatMoney);
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={20}
                                                                            flexGrow={1}
                                                                            label={t('view.PurchaseOrders.Rows.Column.VatPercent')}
                                                                            dataKey={rowFieldNames.Vat}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(formik, arrayProps, rowIndex, columnIndex, dataKey, '0%', (value) => `${value}%`);
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={50}
                                                                            flexGrow={1}
                                                                            label={t('view.PurchaseOrders.Rows.Column.Vat')}
                                                                            dataKey={rowFieldNames.VatAmount}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(formik, arrayProps, rowIndex, columnIndex, dataKey, '0.00', formatMoney);
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            headerRenderer={TableHeader}
                                                                            width={100}
                                                                            flexGrow={1}
                                                                            flexShrink={1}
                                                                            label={t('view.PurchaseOrders.Rows.Column.Total')}
                                                                            dataKey={rowFieldNames.Total}
                                                                            cellRenderer={({ rowIndex, columnIndex, dataKey }) => {
                                                                                return this.renderCell(
                                                                                    formik,
                                                                                    arrayProps,
                                                                                    rowIndex,
                                                                                    columnIndex,
                                                                                    dataKey,
                                                                                    formatMoneyAndCurrency(0, retrievePOLoadable.payload?.Currency),
                                                                                    (value) => formatMoneyAndCurrency(Number(value), retrievePOLoadable.payload?.Currency),
                                                                                );
                                                                            }}
                                                                        />
                                                                        <Column
                                                                            width={16}
                                                                            label=""
                                                                            dataKey="delete"
                                                                            cellRenderer={({ rowIndex }) => {
                                                                                if (this.isReadOnly()) {
                                                                                    return null;
                                                                                }
                                                                                return (
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            this.debounceDeletePoRow(formik.values?.Rows[rowIndex]);
                                                                                        }}
                                                                                        className="purchase-order__remove"
                                                                                        type="button"
                                                                                        data-id={createDataId('purchaseOrderRow', rowIndex, 'removeButton')}
                                                                                    >
                                                                                        <Icon name={ICONS.DELETE} />
                                                                                    </button>
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Table>
                                                                )}
                                                            </AutoSizer>
                                                        )}
                                                    </ArrowKeyStepper>
                                                </ContentBlockBody>
                                            </>
                                        )}
                                    />
                                    <ContentBlockFooter>
                                        <ul className="purchase-order__summary">
                                            <li className="purchase-order__summary-item">
                                                <span className="purchase-order__summary-label">{t('view.PurchaseOrders.Rows.NetTotal')}</span>
                                                <span className="purchase-order__summary-value">{getNetSum(formik.values.Rows, retrievePOLoadable.payload?.Currency)}</span>
                                            </li>
                                            <li className="purchase-order__summary-item">
                                                <span className="purchase-order__summary-label">{t('view.PurchaseOrders.Rows.GrandTotal')}</span>
                                                <span className="purchase-order__summary-value">{getTotalSum(formik.values.Rows, retrievePOLoadable.payload?.Currency)}</span>
                                            </li>
                                        </ul>
                                    </ContentBlockFooter>
                                </ContentBlock>
                            </Form>
                        )}
                    </Formik>
                </MainPageContent>
                <MainPageAside>
                    {retrievePOLoadable?.payload?.OrderStatus === PurchaseOrderStatus.Confirmed && (
                        <Fulfilment loading={retrievePOLoadable.loading} purchaseOrder={retrievePOLoadable.payload} updateExtraStatus={this.props.updateExtraStatus} />
                    )}
                    <Workflow
                        poTaskItemsLoadable={this.props.poTaskItemsLoadable}
                        retrievePOLoadable={retrievePOLoadable}
                        doTaskAction={this.props.doTaskAction}
                        onConfirm={(comment: string, isLastConfirmer: boolean) => {
                            this.handleOnConfirm(comment, isLastConfirmer);
                        }}
                        currentUser={this.props.currentUser}
                        potentialApprovers={this.loadItems}
                        onAddApprover={this.props.addApprover}
                        isApproverAddingAvailable={this.props.isApproverAddingAvailable}
                        isEditting={this.state.isEditting}
                        dataId={'poDetails.workflow'}
                        canAssignByParentComponent={this.letAssignWorkflow}
                    />
                    <RelatedDocuments
                        purchaseOrderId={retrievePOLoadable.payload?.Id}
                        uploadFile={this.props.uploadFile}
                        openFileInNewWindow={this.props.openFileInNewWindow}
                        deleteFile={this.props.deleteFile}
                        downloadFile={this.props.downloadFile}
                        downloadFileLoadable={this.props.downloadFileLoadable}
                        t={t}
                        poFilesLoadable={poFilesLoadable}
                        uploadFileLoadable={this.props.uploadFileLoadable}
                        uploadFileProgress={this.props.uploadFileProgress}
                        isReadOnly={this.isReadOnly()}
                        isEditting={this.state.isEditting}
                        dataId={'poDetails.relatedDocuments'}
                        attachmentsCount={this.state.attachmentsCount}
                    />
                    <PurchaseOrderHistoryWrapper purchaseOrderId={retrievePOLoadable.payload?.Id} />
                </MainPageAside>
            </MainPage>
        );
    }

    revertFieldValue = () => {
        if (!this.state.inputChanged && this.state.previousFocusedCell && this.state.previousFocusedCellValue && this.state.currentForm) {
            this.state.currentForm.setFieldValue(this.state.previousFocusedCell, this.state.previousFocusedCellValue);
        }

        this.setState({ inputChanged: true });
    };

    handleMouseDown = () => {
        this.revertFieldValue();
    };

    handleInputBlur = (e: KeyboardEvent | MouseEvent) => {
        const ignoredKeys = ['ArrowLeft', 'Left', 'ArrowRight', 'Right', 'ArrowUp', 'Up', 'ArrowDown', 'Down'];

        if (e instanceof KeyboardEvent) {
            if (e.key === 'Tab') {
                this.revertFieldValue();
                this.setActiveCell(this.state.selectedRow, undefined);
            }

            if (ignoredKeys.includes(e.key)) {
                this.revertFieldValue();
            }

            this.setState({ inputChanged: true });
        }

        const key = (e as KeyboardEvent).key;

        if (['Esc', 'Escape'].includes(key)) {
            this.setState({
                ignoreNextCellChange: true,
            });
            this.setActiveCell(this.state.selectedRow, undefined);
            return;
        }
        if (e.type === 'blur' || key === 'Enter') {
            this.setActiveCell(this.state.selectedRow, undefined);
            return;
        }

        if ((key && ignoredKeys.includes(key)) || (!this.state.selectedColumn && !this.state.selectedRow)) {
            return;
        }
        const tableContent = document.getElementsByClassName('ReactVirtualized__Table__Grid');
        if (!(tableContent && tableContent[0].contains(e.target as Node))) {
            // TODO: find a better way to lose focus, maybe there is a better one
            this.setActiveCell(this.state.selectedRow, undefined);
        }
    };

    handleKeyDown = (e: KeyboardEvent) => {
        if (!this.state.isArrowNavigationDisabled && e.key === 'Shift') {
            this.setState({
                isArrowNavigationDisabled: true,
            });
        }
    };

    handleKeyUp = (e: KeyboardEvent & { target: HTMLInputElement }) => {
        if (this.state.isArrowNavigationDisabled && e.key === 'Shift') {
            this.setState({
                isArrowNavigationDisabled: false,
            });
        }
    };

    letAssignWorkflow = (): Promise<string> => {
        if (!this.props.retrievePOLoadable?.payload?.Description || !this.props.retrievePOLoadable?.payload?.Supplier) {
            return Promise.resolve(this.props.t('view.PurchaseOrders.Workflow.supplierAndDescMissingMessage'));
        } else {
            return Promise.resolve(null);
        }
    };

    handleResize = () => {
        const windowHeight = window.innerHeight;
        const fixedItemsHeight = 508; // maybe we should measure all the time?
        const tableRowCount = Math.floor((windowHeight - fixedItemsHeight) / this.rowHeight);
        if (this.state.tableRowCount > tableRowCount) {
            this.setState({
                tableVisibleRowCount: tableRowCount > 2 ? tableRowCount : 2,
            });
        } else {
            this.setState({
                tableVisibleRowCount: this.state.tableRowCount,
            });
        }
    };

    debounceHandleResize = debounce(this.handleResize, 100);
    debounceUpdatePoRow = debounce(this.props.updatePoRow);
    debounceDeletePoRow = debounce(this.props.deletePoRow);

    handleFormSideEffects = (newFormikState: FormikProps<{ Rows: PurchaseOrdersRowsDTO[] }>, currentFormikState: FormikProps<PurchaseOrdersRowsDTO[]>) => {
        const { values } = newFormikState;

        if (isEqual(values, currentFormikState.values)) {
            return;
        }

        this.setState(() => {
            return { tableRowCount: values.Rows.length };
        }, this.handleResize);

        if (values?.Rows[this.state.selectedRow]) {
            this.debounceUpdatePoRow(this.props.retrievePOLoadable?.payload?.Id, values.Rows[this.state.selectedRow]);
        }
    };

    selectCell = ({ scrollToColumn, scrollToRow }: ScrollIndices) => {
        // first column is not focusable
        if (scrollToColumn < 1) {
            scrollToColumn = 1;
        }
        // delete is also not focusable
        if (scrollToColumn > 8) {
            scrollToColumn = 8;
        }
        this.setActiveCell(scrollToRow, scrollToColumn);
    };

    handleCellChange = (cellValue: any, dataKey: string, rowIndex: number, formik: FormikProps<{ Rows: PurchaseOrdersRowsDTO[] }>) => {
        const row = {
            ...formik.values.Rows[rowIndex],
            [dataKey]: cellValue,
        };
        if (this.state.ignoreNextCellChange) {
            this.setState({
                ignoreNextCellChange: false,
            });
            row[dataKey] = this.state.previousFocusedCellValue;
            // reset the row value back to the value before focus, use timeout here to guarantee execution after formik's internal change
            setTimeout(() => {
                formik.setFieldValue(`${fieldNames.Rows}[${rowIndex}]`, row);
            }, 0);
            return;
        }

        const rowValueCalculationFn = rowValueCalculationFns[dataKey];
        if (!rowValueCalculationFn) {
            return;
        }
        rowValueCalculationFn(row);
        formik.setFieldValue(`${fieldNames.Rows}[${rowIndex}]`, row);
    };

    private renderCell(
        formik: FormikProps<{ Rows: PurchaseOrdersRowsDTO[] }>,
        arrayProps: FieldArrayRenderProps,
        rowIndex: any,
        columnIndex: any,
        dataKey: string,
        placeholder: string,
        valueFormatter = (value: string) => value,
        maxLength?: number,
    ) {
        const isSelected = this.state.selectedRow === rowIndex && this.state.selectedColumn === columnIndex;
        let validCharacters = DECIMAL_DP2_REGEXP;
        let replaceComma = true;
        if (dataKey === rowFieldNames.Vat) {
            validCharacters = PERCENTAGE_REGEXP;
        }
        if ([rowFieldNames.Description, rowFieldNames.Unit].includes(dataKey)) {
            validCharacters = undefined;
            replaceComma = false;
        }
        if ([rowFieldNames.Quantity, rowFieldNames.UnitPrice].includes(dataKey)) {
            validCharacters = DECIMAL_DP4_REGEXP;
        }
        return (
            <OnFocusField
                dataId={createDataId('purchaseOrderRow', rowIndex, dataKey, 'onFocusField')}
                onFocus={() => {
                    const cell = `${fieldNames.Rows}[${rowIndex}].${dataKey}`;
                    const value = this.getFieldValue(formik, rowIndex, dataKey, undefined);
                    this.setActiveCell(rowIndex, columnIndex);
                    this.setState({
                        previousFocusedCellValue: value,
                        previousFocusedCell: cell,
                        currentForm: formik,
                        inputChanged: false,
                    });
                    // EMR-6093 - Part 1
                    // formik.setFieldValue(cell, '');
                }}
                styles={{
                    height: '40px',
                    lineHeight: '19px',
                    verticalAlign: 'baseline',
                }}
                hasFocus={isSelected}
                textValue={this.getFieldValue(formik, rowIndex, dataKey, valueFormatter)}
                placeholder={placeholder}
                disabled={this.isReadOnly()}
            >
                <FastField
                    dataId={createDataId('purchaseOrderRow', rowIndex, dataKey, 'input')}
                    component={TextInputField}
                    replaceComma={replaceComma}
                    name={`${fieldNames.Rows}[${rowIndex}][${dataKey}]`}
                    type={TextInputType.INLINE_TABLE}
                    autofocus={true}
                    onlyChangeOnBlur={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({ inputChanged: true });
                        this.handleCellChange(e.target.value, dataKey, rowIndex, formik);
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                            this.debounceUpdatePoRow(this.props.retrievePOLoadable?.payload?.Id, emptyRowObject);
                            setTimeout(() => {
                                this.setActiveCell(formik.values.Rows.length, 1);
                            });
                        }
                    }}
                    validCharacters={validCharacters}
                    disabled={this.isReadOnly()}
                    maxLength={maxLength}
                />
            </OnFocusField>
        );
    }

    setActiveCell = (rowIndex: number, columnIndex: number) => {
        this.setState({
            selectedRow: rowIndex,
            selectedColumn: columnIndex,
        });
    };
}

export default PurchaseOrdersAddView;
