import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockHeader } from '../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../components/ContentBlock/ContentBlockHeading';
import { createDataId } from '../../common/utils/dataId';
import { formatDate } from '../../common/utils/formatters';
import { Typography } from '../../components/Ui/Typography';
import { GroupMemberDTO, MemberRoleDTO } from '../../services/types/ApiTypes';
import { LoadableData } from '../../common/utils/LoadableData';
import { Button, ButtonType } from '../../components/Buttons/Button';
import { ICONS, IconSize } from '../../components/Icon/Icon';
import { getRoles } from '../../common/user/userUtils';
import CollapsibleInfoBlockWithHeader from '../../components/CollapsibleInfoBlockWithHeader/CollapsibleInfoBlockWithHeader';

import './UserDetailsCard.scss';

interface Props {
    groupMemberLoadable: LoadableData<GroupMemberDTO>;

    enableEditting: () => void;
}

type UserDetailsCardProps = Props & WithTranslation;

const dataId = 'userDetailsCard';

const UserDetailsCard = (props: UserDetailsCardProps) => {
    // TODO: Memoize this component ?
    const {
        enableEditting,
        groupMemberLoadable: { loading, payload: groupMember },
        t,
    } = props;

    const getActiveRoles = () => getRoles().filter((r: { Id: number; Name: string }) => groupMember?.MemberRoles?.find((role: MemberRoleDTO) => role.Role === r.Id));

    return (
        <ContentBlock loading={loading} className="info-card user-details" dataId={createDataId(dataId, 'content-block')}>
            <ContentBlockHeader>
                <ContentBlockHeading type={ContentBlockHeadingType.H2} dataId={createDataId(dataId, 'header')} className="user-details__heading">
                    <Typography variant="h2" element="span">
                        {t('views.groupMember.groupMember.userDetail')}
                    </Typography>
                    <Button
                        buttonType={ButtonType.ICON_SQUARE}
                        className="confirmation__header-button confirmation__header-button--edit"
                        icon={ICONS.EDIT}
                        iconSize={IconSize.SM}
                        onClick={enableEditting}
                        dataId={createDataId(dataId, 'edit-button')}
                    />
                </ContentBlockHeading>
            </ContentBlockHeader>
            <ContentBlockBody className="px-7 pb-7" dataId="userDetailTags.contentBlockBody">
                <CollapsibleInfoBlockWithHeader isCollapsible={true} headerText={t('views.groupMember.groupMember.generalInformation')} dataId={dataId}>
                    <span className="info-block__field" data-id={createDataId(dataId, 'firstName')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.firstName')}</span>
                        <span className="info-block-value">{groupMember?.User?.FirstName}</span>
                    </span>
                    <span className="info-block__field" data-id={createDataId(dataId, 'lastName')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.lastName')}</span>
                        <span className="info-block-value">{groupMember?.User?.LastName}</span>
                    </span>
                </CollapsibleInfoBlockWithHeader>
                <CollapsibleInfoBlockWithHeader isCollapsible={true} headerText={t('views.groupMember.groupMember.loginInformation')} dataId={dataId}>
                    <span className="info-block__field" data-id={createDataId(dataId, 'personal-id')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.UserGuid')}</span>
                        <span className="info-block-value">{groupMember?.User?.BOGuid}</span>
                    </span>
                    <span className="info-block__field" data-id={createDataId(dataId, 'username')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.userName')}</span>
                        <span className="info-block-value">{groupMember?.User?.Username}</span>
                    </span>
                    <span className="info-block__field" data-id={createDataId(dataId, 'e-mail')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.E_Mail')}</span>
                        <span className="info-block-value">{groupMember?.User?.Email}</span>
                    </span>
                    <span className="info-block__field" data-id={createDataId(dataId, 'personal-code')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.personalCode')}</span>
                        <span className="info-block-value">{groupMember?.User?.PersonalCode || '-'}</span>
                    </span>
                    <span className="info-block__field" data-id={createDataId(dataId, 'country')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.country')}</span>
                        <span className="info-block-value">{groupMember?.User?.Country || '-'}</span>
                    </span>
                </CollapsibleInfoBlockWithHeader>
                <CollapsibleInfoBlockWithHeader isCollapsible={true} headerText={t('views.groupMember.groupMember.membership')} dataId={dataId}>
                    <span className="info-block__field" data-id={createDataId(dataId, 'active')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.status')}</span>
                        <span className="info-block-value">{groupMember?.IsActive ? t('views.groupMember.groupMember.active') : t('views.groupMember.groupMember.notActive')}</span>
                    </span>
                    <span className="info-block__field" data-id={createDataId(dataId, 'membership-start')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.MembershipStart')}</span>
                        <span className="info-block-value">{formatDate(groupMember?.MembershipStart) || '-'}</span>
                    </span>
                    <span className="info-block__field" data-id={createDataId(dataId, 'membership-end')}>
                        <span className="info-block-label">{t('views.groupMember.groupMember.MembershipEnd')}</span>
                        <span className="info-block-value">{formatDate(groupMember?.MembershipEnd) || '-'}</span>
                    </span>
                </CollapsibleInfoBlockWithHeader>
                <CollapsibleInfoBlockWithHeader isCollapsible={true} headerText={t('views.groupMember.detail.Roles')} dataId={dataId}>
                    <span className="info-block__field" data-id={createDataId(dataId, 'roles')}>
                        <span className="info-block-value">
                            {getActiveRoles()
                                .map((r) => t(r.Name))
                                .join(', ')}
                        </span>
                    </span>
                </CollapsibleInfoBlockWithHeader>
                <CollapsibleInfoBlockWithHeader isCollapsible={true} headerText={t('views.groupMember.groupMember.monetaryLimit.title')} dataId={dataId}>
                    <span className="info-block__field" data-id={createDataId(dataId, 'monetaryLimit')}>
                        <span className="info-block-value">{`${groupMember?.MonetaryLimit || t('views.groupMember.groupMember.monetaryLimit.unlimited')} ${groupMember?.MonetaryCurrency || ''}`}</span>
                    </span>
                </CollapsibleInfoBlockWithHeader>
            </ContentBlockBody>
        </ContentBlock>
    );
};

export default withTranslation()(UserDetailsCard);
